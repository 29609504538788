import { Group } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Accessory from "./Accessory";

export default class Head extends Group {
  constructor(transformHelper) {
    const loader = new GLTFLoader();

    super();

    this.name = "head";

    const topPoint = new Group();
    topPoint.position.set(0, 0, 0);

    const accessory = new Accessory();
    topPoint.add(accessory);
    transformHelper.attach(accessory);

    window.onmessage = (e) => {
      if (e.data.transform != null) {
        accessory.position.set(
          e.data.transform.position[0],
          e.data.transform.position[1],
          e.data.transform.position[2]
        );
        accessory.rotation.set(
          e.data.transform.rotation[0],
          e.data.transform.rotation[1],
          e.data.transform.rotation[2]
        );
        accessory.scale.set(
          e.data.transform.scale[0],
          e.data.transform.scale[1],
          e.data.transform.scale[2]
        );
      }
      if (e.data.url != null) {
        accessory.changeAccessory(e.data.url);
      }
    };

    function Done() {
      const transformData = {
        transform: {
          position: [
            accessory.position.x,
            accessory.position.y,
            accessory.position.z,
          ],
          rotation: [
            accessory.rotation.x,
            accessory.rotation.y,
            accessory.rotation.z,
          ],
          scale: [accessory.scale.x, accessory.scale.y, accessory.scale.z],
        },
      };
      parent.postMessage(transformData, "*");
    }
    document.getElementById("done").addEventListener("click", Done);

    document.addEventListener("transformData", transformDataLog, false);
    function transformDataLog(e) {
      console.log(JSON.stringify(e.detail));
    }

    function Reset() {
      accessory.position.set(0, 0, 0);
      accessory.rotation.set(0, 0, 0);
      accessory.scale.set(1, 1, 1);
    }
    document.getElementById("reset").addEventListener("click", Reset);

    loader.load("./headmesh.glb", (gltf) => {
      const root = gltf.scene;
      root.position.set(0, -0.05, -0.05);
      this.add(root, topPoint);
    });
  }
}
