import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  Vector3,
  PMREMGenerator,
  sRGBEncoding,
  Raycaster,
  Vector2,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import SeedScene from "./objects/Scene.js";

const scene = new Scene();
const camera = new PerspectiveCamera();
const renderer = new WebGLRenderer({ antialias: true });

const raycaster = new Raycaster();
const mouse = new Vector2();

// camera
camera.position.set(1, 1, 3);
camera.lookAt(new Vector3(0, 0, 0));

function onMouseMove(event) {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
}

// orbit controls
const orbit = new OrbitControls(camera, renderer.domElement);
orbit.enableDamping = true;
orbit.dampingFactor = 0.25;
orbit.enableZoom = true;

const control = new TransformControls(camera, renderer.domElement);
// control.addEventListener( 'change', onAnimationFrameHandler );

control.addEventListener("dragging-changed", function (event) {
  orbit.enabled = !event.value;
});

function Positioning() {
  control.setMode("translate");
}
document.getElementById("position").addEventListener("click", Positioning);

function Rotate() {
  control.setMode("rotate");
}
document.getElementById("rotation").addEventListener("click", Rotate);

function Scale() {
  control.setMode("scale");
}
document.getElementById("scale").addEventListener("click", Scale);

scene.add(control);

// scene
const seedScene = new SeedScene(control);
scene.add(seedScene);

const pmremGenerator = new PMREMGenerator(renderer);
pmremGenerator.compileEquirectangularShader();
let envMap = undefined;

new RGBELoader().load("world.hdr", (texture) => {
  envMap = pmremGenerator.fromEquirectangular(texture).texture;
  scene.environment = envMap;
  renderer.outputEncoding = sRGBEncoding;
  texture.dispose();
  pmremGenerator.dispose();
});

// renderer
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setClearColor(0x7d7d7d, 1);

// render loop
const onAnimationFrameHandler = (timeStamp) => {
  raycaster.setFromCamera(mouse, camera);
  var intersects = raycaster.intersectObjects(scene.children);
  for (var i = 0; i < intersects.length; i++) {
    intersects[i].object.material.color.set(0xff0000);
  }

  renderer.render(scene, camera);
  seedScene.update && seedScene.update(timeStamp);
  window.requestAnimationFrame(onAnimationFrameHandler);
};
window.requestAnimationFrame(onAnimationFrameHandler);

window.addEventListener("mousemove", onMouseMove, false);

// resize
const windowResizeHandler = () => {
  const { innerHeight, innerWidth } = window;
  renderer.setSize(innerWidth, innerHeight);
  camera.aspect = innerWidth / innerHeight;
  camera.updateProjectionMatrix();
};
windowResizeHandler();
window.addEventListener("resize", windowResizeHandler);

// dom
document.body.style.margin = 0;
document.body.appendChild(renderer.domElement);
