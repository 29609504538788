import { Group } from 'three';
import Head from './Head';
import BasicLights from './Lights';

export default class SeedScene extends Group {
  constructor(transformHelper) {
    super();

    const head = new Head(transformHelper);
    const lights = new BasicLights();

    this.add(head, lights);
  }
}