import { Group, Box3, Vector3, Color } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default class Accessory extends Group {
  constructor() {
    super();
    this.name = "accessory";
  }

  changeAccessory(url) {
    this.remove(this.children[0]);
    const loader = new GLTFLoader();

    loader.load(url, (gltf) => {
      const root = gltf.scene;
      this.add(root);
    });
  }
}
